import Row from 'react-bootstrap/Row'
import GenericLink from '../shared/Link'
import {TeaserListParagraph} from '../../types/paragraphs'
import MediaRenderer from '../shared/MediaRenderer'
import {Col, Container} from 'react-bootstrap'
import classNames from 'classnames'
import {AdditionalContent} from '../../types/api-types'

type Props = {
  paragraph: TeaserListParagraph
  additionalContent: AdditionalContent
}

const TeaserList = ({paragraph, additionalContent}: Props) => {
  const itemCount = paragraph.field_teaser?.length || 0

  const bgPlacement = paragraph.field_media_placement_left_right
  return (
    <section className="px-4 section-box c-teaser-list position-relative">
      <Col
        lg={6}
        className={classNames('w-100 h-100 position-absolute', {
          [`c-teaser-list__background-${bgPlacement}`]: !!bgPlacement,
        })}
      >
        <MediaRenderer
          item={paragraph.field_media}
          layout="fill"
          objectFit="cover"
        />
      </Col>

      <Container fluid className="section-box py-3">
        <Row className="flex-column">
          {paragraph.field_teaser_title?.value ? (
            <h3
              className={`w-100 ${
                paragraph.field_media_placement_left_right === 'left'
                  ? 'text-lg-right ml-lg-auto'
                  : 'text-left'
              } font-weight-bold c-teaser-list__heading`}
              dangerouslySetInnerHTML={{
                __html: paragraph.field_teaser_title.value.replace(
                  '$location',
                  additionalContent.initialLocationQuery?.value ?? '',
                ),
              }}
            />
          ) : null}

          {paragraph.field_description?.value ? (
            <div
              className={`w-100 ${
                paragraph.field_media_placement_left_right === 'left'
                  ? 'text-lg-right ml-lg-auto'
                  : 'text-left'
              } c-teaser-list__short-description`}
              dangerouslySetInnerHTML={{
                __html: paragraph.field_description.value.replace(
                  '$location',
                  additionalContent.initialLocationQuery?.value ?? '',
                ),
              }}
            />
          ) : null}
        </Row>
        <Row
          className={`c-teaser-list__cards c-teaser-list__cards--${itemCount} c-teaser-list__cards--default justify-content-center`}
        >
          {paragraph.field_teaser.map(teaser => {
            return (
              <div
                className="d-flex flex-column justify-content-start c-teaser-list__card w-100 p-4 h-100"
                key={teaser.id}
              >
                {teaser.field_media?.field_media_image.resourceIdObjMeta ? (
                  <MediaRenderer
                    objectFit="contain"
                    imageClass="max-w-100 h-auto"
                    item={teaser.field_media}
                    height={60}
                    width={
                      teaser.field_media.field_media_image.resourceIdObjMeta
                        .width! /
                      (teaser.field_media.field_media_image.resourceIdObjMeta
                        .height! /
                        60)
                    }
                  />
                ) : null}
                <div className="c-teaser-list__card-label">
                  <h5 className="c-teaser-list__card-title font-weight-normal">
                    {teaser.field_title?.replace(
                      '$location',
                      additionalContent.initialLocationQuery?.value ?? '',
                    )}
                  </h5>
                </div>

                <p className="mb-0 flex-grow-1 c-teaser-list__card-text">
                  {teaser.field_short_description.replace(
                    '$location',
                    additionalContent.initialLocationQuery?.value ?? '',
                  )}
                </p>

                {teaser.field_button ? (
                  <GenericLink
                    link={teaser.field_button}
                    extraClass="btn btn-link c-teaser-list__card-button text-tuna-gray-80 mt-3 text-left pl-0 "
                  >
                    {teaser.field_button.title?.replace(
                      '$location',
                      additionalContent.initialLocationQuery?.value ?? '',
                    )}
                  </GenericLink>
                ) : null}
              </div>
            )
          })}
        </Row>
      </Container>
    </section>
  )
}

export default TeaserList
